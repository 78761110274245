import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  Chip,
} from "@mui/material";

const WriterScriptEntry = () => {
  const [writers, setWriters] = useState([]);
  const [selectedWriter, setSelectedWriter] = useState(null);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState("");
  const [googleDocLink, setGoogleDocLink] = useState("");
  const [scripts, setScripts] = useState([]);
  const [filter, setFilter] = useState(""); // Filter by status
  const [sortOrder, setSortOrder] = useState("desc"); // Sort order
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prefixType, setPrefixType] = useState("Trope");
  const [prefixNumber, setPrefixNumber] = useState("1");

  useEffect(() => {
    fetchAllWriters();
  }, []);

  const fetchAllWriters = async () => {
    try {
      const response = await axios.get("/api/admin/writers");
      setWriters(response.data);
    } catch (error) {
      console.error("Error fetching writers:", error);
      setError("Error fetching writers");
    }
  };

  const fetchScripts = async (writer_id) => {
    try {
      const response = await axios.get(`/api/scripts?writer_id=${writer_id}`);
      setScripts(response.data);
    } catch (error) {
      console.error("Error fetching scripts:", error);
      setError("Error fetching scripts");
    }
  };

  const handleWriterChange = (writerId) => {
    const writer = writers.find((w) => w.id === writerId);
    setSelectedWriter(writer);
    fetchScripts(writer.id);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedWriter) {
      setError("Please select a writer first.");
      return;
    }

    setError(null);
    setIsSubmitting(true);

    try {
      const fullTitle =
        prefixType === "Original"
          ? `[${prefixType}] ${title}`
          : `[${prefixType} ${prefixNumber}] ${title}`;

      const response = await axios.post("/api/scripts", {
        writer_id: selectedWriter.id,
        title: fullTitle,
        googleDocLink,
      });

      setScripts([...scripts, response.data]);
      setTitle("");
      setGoogleDocLink("");
      alert("Script submitted successfully!");
    } catch (error) {
      setError(error.response?.data?.error || "Error submitting script");
    } finally {
      setIsSubmitting(false);
    }
  };

  const getStatusStyle = (status) => {
    switch (status.toLowerCase()) {
      case "pending":
        return { backgroundColor: "yellow", color: "black" };
      case "rejected":
        return { backgroundColor: "red", color: "white" };
      case "posted":
        return { backgroundColor: "purple", color: "white" };
      default:
        return { backgroundColor: "grey", color: "white" };
    }
  };

  const filterScripts = () => {
    let filteredScripts = [...scripts];

    if (filter) {
      filteredScripts = filteredScripts.filter(
        (script) =>
          script.approval_status.toLowerCase() === filter.toLowerCase()
      );
    }

    filteredScripts.sort((a, b) =>
      sortOrder === "desc"
        ? new Date(b.created_at) - new Date(a.created_at)
        : new Date(a.created_at) - new Date(b.created_at)
    );

    return filteredScripts;
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography
        variant="h5"
        sx={{
          mb: 2,
          fontWeight: "bold",
          textAlign: "left",
          color: "#333",
          marginBottom: "40px",
        }}
      >
        Manage Writer Scripts
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}

      <Grid container spacing={4} alignItems="stretch">
        {/* New Script Submission */}
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              padding: 3,
              borderRadius: 2,
              boxShadow: 2,
              backgroundColor: "#fff",
             
              width:"600px"
            }}
          >
            <Typography variant="h5" gutterBottom>
              New Script Submission
            </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Select Writer</InputLabel>
              <Select
                value={selectedWriter?.id || ""}
                onChange={(e) => handleWriterChange(parseInt(e.target.value))}
              >
                {writers.length > 0 ? (
                  writers.map((writer) => (
                    <MenuItem key={writer.id} value={writer.id}>
                      {writer.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No writers available</MenuItem>
                )}
              </Select>
            </FormControl>
            <TextField
              label="Title"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{ mb: 2 }}
              disabled={!selectedWriter}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={prefixType}
                    onChange={(e) => setPrefixType(e.target.value)}
                    disabled={!selectedWriter}
                  >
                    <MenuItem value="Trope">Trope</MenuItem>
                    <MenuItem value="Original">Original</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Number"
                  fullWidth
                  type="number"
                  value={prefixNumber}
                  onChange={(e) => setPrefixNumber(e.target.value)}
                  disabled={!selectedWriter || prefixType === "Original"}
                />
              </Grid>
            </Grid>
            <TextField
              label="Google Doc Link"
              fullWidth
              value={googleDocLink}
              onChange={(e) => setGoogleDocLink(e.target.value)}
              sx={{ mt: 2 }}
              disabled={!selectedWriter}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
              sx={{ mt: 2 }}
              disabled={!selectedWriter || isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>
          </Box>
        </Grid>

        {/* Previous Submissions */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              padding: 3,
              borderRadius: 2,
              boxShadow: 2,
              backgroundColor: "#fff",
              height: "100%",
            }}
          >
            <Typography variant="h5" gutterBottom>
              Previous Submissions
            </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Filter By Status</InputLabel>
              <Select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <MenuItem value="">All Statuses</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
                <MenuItem value="Posted">Posted</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              onClick={() =>
                setSortOrder((prev) => (prev === "desc" ? "asc" : "desc"))
              }
              fullWidth
              sx={{ mb: 2 }}
            >
              Sort By Date: {sortOrder === "desc" ? "Newest" : "Oldest"}
            </Button>
            {scripts.length > 0 ? (
              filterScripts().map((script) => (
                <Card key={script.id} sx={{ mb: 1 }}>
                  <CardContent sx={{ padding: "8px" }}>
                    <Typography variant="h6">{script.title}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Submitted on:{" "}
                      {script.created_at
                        ? new Date(script.created_at).toLocaleDateString()
                        : "Unknown"}
                    </Typography>
                    {script.google_doc_link && (
                      <Button
                        href={script.google_doc_link}
                        target="_blank"
                        sx={{ mt: 1 }}
                      >
                        Open Google Doc
                      </Button>
                    )}
                    {script.approval_status.toLowerCase() === "rejected" &&
                      script.loom_url && (
                        <Button
                          href={script.loom_url}
                          target="_blank"
                          color="secondary"
                          sx={{ mt: 1 }}
                        >
                          View Loom Video
                        </Button>
                      )}
                    <Box sx={{ mt: 1 }}>
                      <Chip
                        label={script.approval_status || "Unknown"}
                        style={getStatusStyle(
                          script.approval_status || "unknown"
                        )}
                      />
                    </Box>
                  </CardContent>
                </Card>
              ))
            ) : (
              <Typography>No submissions yet.</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WriterScriptEntry;

