import React, { useState } from "react";
import { Link, useLocation, Outlet } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Avatar,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import BarChartIcon from "@mui/icons-material/BarChart"; // Competitors icon

const drawerWidthOpen = 210;
const drawerWidthClosed = 64;

const LeftSideNavbar = ({ isDrawerOpen }) => {
  const location = useLocation();
  const isActive = (path) => location.pathname.includes(path);

  return (
    <Drawer
      sx={{
        width: isDrawerOpen ? drawerWidthOpen : drawerWidthClosed,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isDrawerOpen ? drawerWidthOpen : drawerWidthClosed,
          boxSizing: "border-box",
          transition: "width 0.3s",
          top: 0,
          position: "fixed",
          height: "100%",
          overflowX: "hidden",
          backgroundColor: "#F8FAFC",
        },
      }}
      variant="permanent"
      open={isDrawerOpen}
    >
      {/* Header Section */}
      <Box
  sx={{
    display: "flex",
    alignItems: "center",
    p: 1,
    backgroundColor: "#F8FAFC",
    border: "1px solid #D1D5DB",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
    borderRadius: "6px",
    m: 2,
  }}
>
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 30,
      height: 30,
      backgroundColor: "#E8F0FE",
      borderRadius: "50%",
      mr: 2,
      ml:1
    }}
  >
    <DevicesRoundedIcon
      sx={{ fontSize: 20, color: "#1976D2"}}
    />
    
  </Box>
  {isDrawerOpen && (
    <Box>
      <Typography
        variant="h6"
        sx={{ fontSize: "0.9rem", fontWeight: "bold" }}
      >
        Plotpointe
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ fontSize: "0.75rem" }}
      >
        Web app
      </Typography>
    </Box>
  )}
  {isDrawerOpen && (
    <IconButton size="small" sx={{ ml: "auto", color: "#6B7280" }}>
      <ArrowDropDownIcon sx={{ fontSize: 16 }} />
    </IconButton>
  )}
</Box>

      <Divider
        sx={{
          borderColor: "#D1D5DB",
          marginY: 0.5,
        }}
      />

      {/* Navigation Menu */}
      <List sx={{ mt: 0.5 }}>
        {[
          {
            text: "Home",
            icon: <HomeRoundedIcon fontSize="small" />,
            link: "/admindashboard",
          },
          {
            text: "Video Entry",
            icon: <VideoLibraryIcon fontSize="small" />,
            link: "/video-entry",
          },
          {
            text: "Sponsored",
            icon: <MonetizationOnIcon fontSize="small" />,
            link: "/sponsored",
          },
          {
            text: "Non-Sponsored",
            icon: <VideoLabelIcon fontSize="small" />,
            link: "/non-sponsored",
          },
          {
            text: "Employees",
            icon: <PeopleRoundedIcon fontSize="small" />,
            link: "/pendingwriters",
          },
          {
            text: "Script Entry",
            icon: <MenuBookRoundedIcon fontSize="small" />, // Changed the icon to MenuBookRoundedIcon
            link: "/writer-script-entry", // Updated path for Writers Script
          },
          {
            text: "Competitors",
            icon: <BarChartIcon fontSize="small" />, // Competitors Icon
            link: "/repost  ",
          },
        ].map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              component={Link}
              to={item.link}
              selected={isActive(item.link)}
              sx={{
                borderRadius: 2,
                px: 0.8, // Reduced padding
                marginLeft: "10px",
                marginRight: "10px",
                color: isActive(item.link) ? "#111827" : "#6B7280",
                backgroundColor: isActive(item.link)
                  ? "#E5E7EB"
                  : "transparent",
                "&:hover": {
                  backgroundColor: "#E5E7EB",
                  color: "#111827",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color: isActive(item.link) ? "#111827" : "#6B7280",
                  minWidth: "25px", // Reduced width for smaller spacing
                  "& svg": {
                    fontSize: "15px", // Smaller font size for icons
                  },
                }}
              >
                {item.icon}
              </ListItemIcon>

              {isDrawerOpen && (
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    fontSize: "0.875rem", // Smaller font size
                    fontWeight: 530,
                  }}
                />
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider
        sx={{
          borderColor: "#D1D5DB",
          marginY: 0.5,
        }}
      />

      {/* Settings */}
      <List>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/settings"
            selected={isActive("/settings")}
            sx={{
              borderRadius: 2,
              justifyContent: isDrawerOpen ? "flex-start" : "center",
              color: isActive("/settings") ? "#111827" : "#6B7280",
              backgroundColor: isActive("/settings")
                ? "#E5E7EB"
                : "transparent",
              "&:hover": {
                backgroundColor: "#E5E7EB",
                color: "#111827",
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: isActive("/settings") ? "#111827" : "#6B7280",
                minWidth: "25px", // Reduced width
                "& svg": {
                  fontSize: "15px", // Smaller icon
                },
              }}
            >
              <SettingsRoundedIcon fontSize="small" />
            </ListItemIcon>
            {isDrawerOpen && (
              <ListItemText
                primary="Settings"
                primaryTypographyProps={{
                  fontSize: "0.875rem", // Smaller font size
                  fontWeight: 530,
                }}
              />
            )}
          </ListItemButton>
        </ListItem>

        {/* Logout */}
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/logout"
            sx={{
              borderRadius: 2,
              justifyContent: isDrawerOpen ? "flex-start" : "center",
              color: "#6B7280",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "#E5E7EB",
                color: "#111827",
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: "#6B7280",
                minWidth: "25px", // Reduced width
                "& svg": {
                  fontSize: "15px", // Smaller icon
                },
              }}
            >
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            {isDrawerOpen && (
              <ListItemText
                primary="Logout"
                primaryTypographyProps={{
                  fontSize: "0.875rem", // Smaller font size
                  fontWeight: 530,
                }}
              />
            )}
          </ListItemButton>
        </ListItem>
      </List>

      {/* Footer Section */}
      <Box
        sx={{
          mt: "auto",
          display: "flex",
          alignItems: "center",
          p: 0.8, // Reduced padding
          borderTop: "1px solid #E5E7EB",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Avatar
          alt="Admin"
          src="/static/images/avatar/1.jpg"
          sx={{ width: 24, height: 24, mr: 0.5 }} // Smaller avatar
        />
        {isDrawerOpen && (
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "0.875rem", fontWeight: "bold" }} // Smaller font size
            >
              Admin
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontSize: "0.7rem" }} // Smaller font size
            >
              admin@plotpointe.com
            </Typography>
          </Box>
        )}
      </Box>


    </Drawer>
  );
};

const Layout = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(true);

  return (
    <Box sx={{ display: "flex" }}>
      <LeftSideNavbar isDrawerOpen={isDrawerOpen} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2, // Reduced padding
          transition: "margin 0.3s",
          marginLeft: `${isDrawerOpen ? drawerWidthOpen : drawerWidthClosed}px`,
          backgroundColor: "#fff",
          position: "absolute",
          scrollBehavior: "auto",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;

